import { GenerateOtp } from '@/features/onboarding/v1/signup/types';

import { useHttpMutation } from '@/shared/hooks';

export interface GenerateOtpResponse {
  code?: string;
  message?: string;
  rateLimit?: {
    reset: number;
  };
}

const useGenerateOtp = (
  options?: Parameters<
    typeof useHttpMutation<GenerateOtp, GenerateOtpResponse>
  >[0],
) => {
  return useHttpMutation<GenerateOtp, GenerateOtpResponse>({
    method: 'POST',
    url: '/identity/v1/verifications',
    ...options,
  });
};

export default useGenerateOtp;
