import { useMediaQuery } from '@kamona/utils-v2';

export const useDeviceDetect = () => {
  const isTablet = useMediaQuery({
    minWidth: 744,
    maxWidth: 1023,
  });
  const isMobile = useMediaQuery({ maxWidth: 743 });
  const isTabletOrMobile = isTablet || isMobile;

  return {
    isTablet,
    isMobile,
    isTabletOrMobile,
  };
};
